import React, { useEffect, useRef, useState } from 'react'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { postJSON } from '@bothrs/util/fetch'

import { useAnalytics } from '@healthblocks-io/core/analytics'
import { unsafeDecode, useAuth } from '@healthblocks-io/core/auth'
import {
  useApi,
  useConfig,
  usePid,
  useProjectContext,
} from '@healthblocks-io/core/project'

import Button from 'pages/atoms/Button'
import Input from 'pages/atoms/Input'

import cls from './login.module.scss'
import { HBAuth0Provider } from 'lib/auth0'

function useConfigAuth0() {
  return useConfig(p => p.config.auth0?.dashboard || p.config.auth0)
}

export default function Login() {
  const { data, error } = useProjectContext()
  if (error) {
    return <Wrapper>{error?.message || JSON.stringify(error)}</Wrapper>
  }

  if (data) {
    const auth0 = data.config.auth0?.dashboard || data.config.auth0
    return auth0 ? (
      <HBAuth0Provider>
        <LoginAuth0Check config={auth0} />
      </HBAuth0Provider>
    ) : (
      <Wrapper>No authentication methods available.</Wrapper>
    )
  }

  // Don't render stuff while logging in
  if (
    window.location.href.includes('state=') &&
    window.location.href.includes('code=')
  ) {
    return <Wrapper>Signing in!</Wrapper>
  }

  return <Wrapper>Loading project data...</Wrapper>
}

function LoginAuth0Check({ config }) {
  const signingIn = useRef(false)
  const { track } = useAnalytics()
  const { signInWithToken } = useAuth()
  const { error, getIdTokenClaims, isAuthenticated, user } = useAuth0()

  // Watch if a user is signed in, that means sign in was successful
  useEffect(() => {
    // Wait for user to be loaded
    if (!user) return

    // Only try to sign in once
    if (signingIn.current) return
    signingIn.current = true

    // Trade id token for HB auth token
    ;(async () => {
      try {
        const claims = await getIdTokenClaims()
        // @ts-expect-error
        const auth: { id_token: string } = await signInWithToken(claims.__raw)
        if (!auth.id_token?.startsWith('eY'))
          window.Sentry?.captureMessage('Unexpected id token', {
            extra: { component: 'LoginAuth0Check' },
          })

        const user = unsafeDecode(auth.id_token)
        track('User Signed In', {}, user.sub)
      } catch (e) {}
      return
    })()
  }, [!user])

  if (error) {
    return <Wrapper>Oops... {error.message}</Wrapper>
  }
  if (signingIn.current) {
    return <Wrapper>Signing in!</Wrapper>
  }
  if (isAuthenticated) {
    return <Wrapper>Authenticated!</Wrapper>
  }

  // Show passwordless UI and option for Auth0
  if (config.passwordless_email_code) {
    return <PasswordlessAuth0 />
  }

  // Don't show UI and immediately redirect to Auth0
  return <Redirecting />
}

/** Don't show UI and immediately redirect to Auth0 */
function Redirecting() {
  const planned = useRef(false)
  const { isLoading, loginWithRedirect } = useAuth0()
  useEffect(() => {
    if (isLoading) return

    if (planned.current) return
    planned.current = false

    let mounted = true
    setTimeout(() => {
      mounted && loginWithRedirect({ prompt: 'select_account' })
      return () => {
        mounted = false
      }
    }, 1000)
  }, [isLoading])
  return <div>Redirecting...</div>
}

/** Show passwordless UI and option for Auth0 */
function PasswordlessAuth0() {
  const { handleAuth } = useAuth()
  const api = useApi()
  const pid = usePid()
  const configAuth0 = useConfigAuth0()
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [requested, setRequested] = useState('')
  const [error, setError] = useState(false)

  const { loginWithPopup } = useAuth0()

  const sendVerificationCode = email => {
    console.log('sendVerificationCode', email)
    const data = {
      client_id: configAuth0.clientId,
      connection: email.includes('@') ? 'email' : 'sms',
      email: email.includes('@') ? email : undefined,
      phone_number: !email.includes('@') ? email : undefined,
      send: 'code', //if left null defaults to link
      // send: "link",
      // authParams: {
      //   redirect_uri: "http://localhost:3000",
      //   scope: "openid profile email offline_access",
      // },
    }
    // console.log('post pass', data)
    postJSON(
      'https://' + configAuth0.domain + '/passwordless/start',
      data
    ).then(x => {
      console.log('passwordless started', x)
    })
    setRequested(email)
  }

  const verify = password => {
    const data = {
      audience: 'https://' + configAuth0.domain + '/api/v2/',
      client_id: configAuth0.clientId,
      grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
      realm: requested.includes('@') ? 'email' : 'sms',
      username: requested, //email or phone number
      otp: password,
      scope: 'openid profile email offline_access',
    }

    // console.log('data', data)
    postJSON(api + '/api/oauth/token?role=admin&pid=' + pid, data)
      .then(jwt => {
        console.log('oauth.token response', jwt)
        handleAuth(jwt)
        if (jwt.error) {
          setError(jwt.error)
        }
      })
      .catch(e => {
        console.log('oauth.token error', e.message)
        setError(e)
      })
  }

  if (!requested) {
    return (
      <Wrapper>
        <h2>Hi There!</h2>

        <p>What's your sign-in email address?</p>
        <form
          onSubmit={e => {
            e.preventDefault()
            sendVerificationCode(email)
          }}
        >
          <Input
            label="Email address"
            placeholder="me@healthblocks.io"
            type="email"
            id="email"
            name="email"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
          />
          <Button>Login</Button>
        </form>
        {pid !== 'biosil' && (
          <Button
            onClick={() => loginWithPopup({ prompt: 'select_account' })}
            subtle
            type="button"
            style={{ marginBottom: 0 }}
          >
            more login options
          </Button>
        )}
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <h2>An email is on it's way!</h2>
      <p className={cls.info}>
        We sent an email to <strong>{email}</strong>. <br />
        This link expires in 24h, so be sure to use it soon.
      </p>
      <p className={cls['go-check']}>Go check your email to login!</p>
      {error && (
        <span className={cls.error}>
          {typeof error === 'string'
            ? error === 'auth failed 36'
              ? 'Authentication failed'
              : error
            : 'Something went wrong'}
        </span>
      )}
      <form
        onSubmit={e => {
          e.preventDefault()
          verify(code)
        }}
      >
        <Input
          placeholder="code"
          type="code"
          id="code"
          name="code"
          value={code}
          required
          onChange={e => setCode(e.target.value)}
        />
        <Button>send code</Button>
      </form>
    </Wrapper>
  )
}

function Wrapper({ children }) {
  const requested = false
  return (
    <section className={cls['login-page']}>
      <div className={cls['login-wrap']}>
        <img src="/images/healthblocks-logo-white.png" alt="logo" />
        {children}
      </div>
      {!requested && (
        <div className={cls.secondaryActions}>
          <p>
            Don't have an email yet?{' '}
            <a href="mailto:support@healthblocks.io?subject=Request access Healthblocks">
              Request access
            </a>
          </p>
          {/* <p>
            Forgot your password buddy? <button>Get a new password</button>
          </p> */}
        </div>
      )}
    </section>
  )
}
